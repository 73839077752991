@charset "utf-8";

.is-transparent {
    background: none !important; }
.is-fullheight {
  height: 100%; }
.is-bold {
  font-weight: 500; }
.is-centered {
  margin: 0 auto; }
.is-warning {
  color: $warning; }

.alignright {
  float: right; }
.alignleft {
  float: left; }
.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }
