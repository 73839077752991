@charset "utf-8";

h1,
h2,
h3,
h4,
h5,
h6,
.content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6, {
 } }    //line-height: 1.5 !important

pre {
  padding: 20px;
  overflow-x: scroll;
  margin-bottom: 1em; }
