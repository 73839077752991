@charset "utf-8";
.publications {
    .columns {
        align-items: stretch;
    }
    .publications-block {
        padding-bottom: 20px;
        .inner-block {
            background-color: $white;
            border: 2px solid $black;
            padding: 20px;
            height: 100%;
            width: calc(100% - 15px);
            
            h3 {
                margin-top: 0;
            }
            a {
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                    h3 {
                        text-decoration: underline;
                    }
                }
            }
        }
        &:nth-child(1),
        &:nth-child(5),
        &:nth-child(9),
        &:nth-child(13),
        &:nth-child(17),
        &:nth-child(21),
        &:nth-child(25),
        &:nth-child(29),
        &:nth-child(33),
        &:nth-child(37),
        &:nth-child(41),
        &:nth-child(45),
        &:nth-child(49) {
            .inner-block {
                border-color: $brand-green-light;
                box-shadow: 15px 15px 0px $brand-green-light;
            }
        }
        &:nth-child(2),
        &:nth-child(6),
        &:nth-child(10),
        &:nth-child(14),
        &:nth-child(18),
        &:nth-child(22),
        &:nth-child(26),
        &:nth-child(30),
        &:nth-child(34),
        &:nth-child(38),
        &:nth-child(42),
        &:nth-child(46),
        &:nth-child(50) {
            .inner-block {
                border-color: $brand-blue-light;
                box-shadow: 15px 15px 0px $brand-blue-light;
            }
        }
        &:nth-child(3),
        &:nth-child(7),
        &:nth-child(11),
        &:nth-child(15),
        &:nth-child(19),
        &:nth-child(23),
        &:nth-child(27),
        &:nth-child(31),
        &:nth-child(35),
        &:nth-child(39),
        &:nth-child(43),
        &:nth-child(47),
        &:nth-child(51) {
            .inner-block {
                border-color: $brand-red-light;
                box-shadow: 15px 15px 0px $brand-red-light;
            }
        }
        &:nth-child(4),
        &:nth-child(8),
        &:nth-child(12),
        &:nth-child(16),
        &:nth-child(20),
        &:nth-child(24),
        &:nth-child(28),
        &:nth-child(32),
        &:nth-child(36),
        &:nth-child(40),
        &:nth-child(44),
        &:nth-child(48),
        &:nth-child(52) {
            .inner-block {
                border-color: $brand-yellow;
                box-shadow: 15px 15px 0px $brand-yellow;
            }
        }
    }
}



button[data-publications--load-more],
button.load-more {
    display: block;
    margin: 5px auto 0;
    font-size: 32px;
    color: $brand-blue;
    border: 2px solid $brand-blue;
    background: transparent;
    line-height: 42px;
    padding: 12px 17px;
    cursor: pointer;
    font-family: "Alvar Essential";
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
        margin-left: 40px;
    }
}