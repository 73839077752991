.gform_wrapper {

	.ginput_container_number {

		input {
			padding-bottom: $gravity-form-input-padding / 2;
			padding-top: $gravity-form-input-padding / 2;
		}
	}
}
