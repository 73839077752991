@charset "utf-8";

.two-columns-callout-slider {
    //padding: 0 !important;
    background: linear-gradient(to bottom, $brand-blue-light, $brand-blue-light 75%, $brand-green-light 75%);
    @media(min-width: $tablet) {
        background: linear-gradient(to right, $brand-blue-light, $brand-blue-light 60%, $brand-green-light 60%);
    }
    .columns {
        .column { 
            @media(max-width: $tablet){
            padding-top: 22px;
            padding-bottom: 22px;
            }
        }
        .carousel {
            &.slick-initialized .slick-slide,
            .slick-slide {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                text-align: center;
            }
        }
    }

}