@charset "utf-8";

.site-footer {
    background-color: $brand-blue;
    padding: 40px 30px;
    color: $white;
    a {
        color: $white;
        text-decoration: none;
    }
    .footer-nav {
        @media(min-width: $desktop) {
            width: 75%;
            display: inline-block;
        }
        .columns {
            margin: 0;
            .column {
                margin: 0 0 30px 0;
                @media(min-width: $desktop) {
                    margin: 0;
                }
            }
        }
        .navbar-heading {
            font-weight: 600;
        }
        .navbar-item,
        .navbar-link {
            color: $white;
            padding: 0;
            background: transparent;
            &:focus, 
            &:focus-within, 
            &:hover, 
            &.is-active {
                opacity: 1;
                color: $white;
            }
        }
        .navbar-item > .navbar-link {
            font-weight: 600;
        }
        .navbar-link {
            &:focus, 
            &:focus-within, 
            &:hover, 
            &.is-active {
                opacity: 1;
                color: $white;
            }
        }
        a {
            &:focus, 
            &:focus-within, 
            &:hover, 
            &.is-active {
                opacity: 1;
                color: $white;
                text-decoration: underline;
            }
        }
        .navbar-start > .navbar-item {
            flex-direction: column;
            font-size: 21px;
            
            .navbar {
                margin-top: 10px;
                flex-direction: column;
                font-weight: 500;
                .navbar-item {
                    margin-bottom: 5px;
                }
            }
        }
    }
    .footer-search {
        @media(min-width: $desktop) {
            width: 24%;
            display: inline-block;
        }
        .searchform {
            .control.has-addons .input {
                max-width: 100%;
                border: none;
                background-color: rgba($white, 0.20);
                color: $white;
                font-weight: 600;
                &::placeholder {
                    color: rgba($white, 1);
                }
                i {
                    font-weight: 600;
                    &:after {
                        font-weight: 600;
                    }
                }
            }
            .search-submit {
                font-size: 21px;
                font-weight: 300;
                position: absolute;
                z-index: 2;
                top: 6px;
                right: 2px;
                background: transparent;
                border: none;
                color: $white;
            }
        }
    }
}