.error-404 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    min-height: 65vh;

    header {
        padding-bottom: 17px;
    }

    h1 {
        color: $brand-blue;
    }

    > div:last-of-type {
        margin-left: 47px;
    }

    .button {
        background-color: $brand-red;
        border-color: $brand-red;
        color: $white;
    }

    .page-content .text {
        margin-bottom: 36px;
    }
}