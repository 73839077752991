.alt-transport-resources {
    margin-top: 45px;
    margin-bottom: 45px;

    ul {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        list-style: none;
        padding-right: 30px;
        li {
            margin-bottom: 30px;
            width: 100%;

            span {
                display: block;
                font-size: 24px;
                font-weight: 600;
                color: #000;
            }
        }
    }

    @media(min-width: 1024px) {
        ul li {
            &:nth-of-type(odd) {
                margin-right: 25px;
            }
            width: calc(50% - 12.5px);

            span {
                font-weight: 28px;
            }
        }
    }
}
#react-mount--map-tabs {
    button {
        &:focus {
            outline: none;
        }
    }
}