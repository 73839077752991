@charset "utf-8";
.main-navigation {
  display: flex;
  position: relative; }

.main-navigation ul {
  display: none; }

.navbar-brand {
  .navbar-item {
    &:hover {
      background: inherit; } } }

.navbar-item {
  color: $text-light;
  &.is-muted {
    color: #333;
    opacity: .3;
    &:hover {
      color: #333;
      opacity: .3; } }

  &.has-dropdown {
    padding: 0; }

  &:hover {
    color: $link-hover;
    background: inherit; }

  // Modifiers
  &.is-active {
    color: $link-active; }
  &.is-tab {
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    padding-left: 12px;
    padding-right: 12px;
    &:hover {
      border-bottom: 1px solid $link;
      border-top: 1px solid transparent; }
    &.is-active {
      border-bottom: 3px solid $link;
      border-top: 3px solid transparent;
      color: $link; } } }

.navbar-link {
  width: 100%; }

// Nav Links
.nav-links {
  display: flex;
  justify-content: space-between; }

.nav-links .level:not(:last-child) {
  margin-bottom: 0 !important; }

// Small menu
.menu-toggle,
.main-navigation.toggled ul {
  display: flex;
  flex-direction: column;
  width: 100%; }

@media screen and (min-width: 767px) {
  .menu-toggle {
    display: none; }

  .main-navigation ul {
    display: flex;
    flex-wrap: wrap;
    &.dropdown-menu {
      display: none;
      position: absolute;
      top: $navbar-height;
      right: 0; } } }

// Hide second level & up navigation until implemented properly
.dropdown-menu {
  display: none !important; }

.navbar-menu {
  &.is-active {
    position: absolute;
    width: 100%;
    z-index: 200; } }
