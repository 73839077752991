@charset "utf-8";

.button {
  &.is-clear {
    background: none;
    outline: 0;
    border: none;
    height: auto;
    width: auto;
    padding: 0;
    margin: 0; } }

.post-comment-button {
  padding-left: 2em;
  padding-right: 2em; }
