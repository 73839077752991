@charset "utf-8";
.slider-with-image-and-flag {
    @media (max-width: $tablet) {
        padding: 40px 20px;
    }
    .flag {
        @media(max-width: $tablet){
            font-size: 26px !important;
            padding: 8px 15px !important;
        }
        padding: 8px 25px;
        display: inline-block;
        position: absolute;
        top: -50px;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
        min-width: 280px;
        @media(min-width: $tablet) {
            transform: translateX(0%);
            top: -30px;
            left: -25px;
        }
    }
    .carousel {
        z-index: 1;
        margin-bottom: 0 !important;
        background: #fff;
        .slick-list {
            box-shadow: none !important;
            border: 2px solid $brand-blue-light;
            .slide {
                background: #fff;
                padding: 0;
                .text-container {
                    padding: 30px;
                    ul {
                        margin-bottom: 30px;
                    }
                }
            }
            @media(max-width: $desktop){
                .column {
                    display: block;
                    flex-basis: 0;
                    flex-grow: 1;
                    flex-shrink: 1;
                }
            }
        }
        .slick-dots {
            @media (min-width: $tablet) {
                width: 50%;
                left: 50%;
            }
            li {
                button {
                    border-color: $brand-blue-light !important;
                }
                &.slick-active button {
                    background-color: $brand-blue-light !important;
                }
            }
        }
        @media (min-width: $tablet) {
            .slick-next {
                right: 12.5%;
            }
            .slick-prev {
                left: 62.5%
            }

        }
       
    }
}