@charset "utf-8";
.resource-blocks {
    .column {
        padding: 17px;
        .resource-block {
            background: #fff;
            border: 2px solid $black;
            box-shadow: 15px 15px 0 $black;
            width: calc(100% - 15px);
            height: 100%;
            display: flex;
            flex-direction: column;
            img {
                display: block;
            }
            .text-container {
                padding: 30px;
                align-self: stretch;
                flex: 1;
                h5 {
                    margin-bottom: 0;
                }
                h3 {
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
                a {
                    @media (min-width: $tablet) {
                        position: absolute;
                        bottom: 35px;
                    }
                    
                }
            }
        }
        &:nth-child(1),
        &:nth-child(1n + 4) {
            .resource-block {
                border-color: $brand-green-light;
                box-shadow: 15px 15px 0px $brand-green-light;
            }
        }
        &:nth-child(2),
        &:nth-child(2n + 4) {
            .resource-block {
                border-color: $brand-blue-light;
                box-shadow: 15px 15px 0px $brand-blue-light;
            }
        }
        &:nth-child(3),
        &:nth-child(3n + 4) {
            .resource-block {
                border-color: $brand-red-light;
                box-shadow: 15px 15px 0px $brand-red-light;
            }
        }
        &:nth-child(4),
        &:nth-child(4n + 4) {
            .resource-block {
                border-color: $brand-yellow;
                box-shadow: 15px 15px 0px $brand-yellow;
            }
        }
    }
}