@charset "utf-8";

.one-column {
    .column {
        @media (min-width: $tablet) {
            max-width: 860px;
            margin-right: auto;
            margin-left: auto;
        }
    }
}