.gform_wrapper {

	.gfield_checkbox,
	.gfield_radio {
		margin-top: 0;

		li {
			align-items: center;
			display: flex;
			line-height: 1;
			margin: 0 0 $gravity-margin-small;

			input {
				margin-right: $gravity-margin-small;
			}
		}
	}
}
