@charset "utf-8";

.two-columns {
    .column {
        @media (min-width: $tablet) {
            &:first-of-type { 
                border-right: 1px solid $brand-blue;
                padding: 30px;
            }
            &:last-of-type { 
                border-left: 1px solid $brand-blue;
                padding: 30px;
            }
        }
    }
}