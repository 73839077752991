@charset "utf-8";

.card {
  width: 100%;
  height: 100%;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  .card-image {
    min-height: 200px; }
  .card-content {
    min-height: 260px;
    .title + .subtitle {
      margin-top: 0; }
    .entry-footer {
      margin-top: 20px; } }

  .image.is-square,
  .image.is-1by1,
  .image.is-4by3,
  .image.is-3by2,
  .image.is-16by9,
  .image.is-2by1 {
    overflow: hidden;
    img {
      top: 50%;
      height: auto;
      width: 100% !important;
      transform: translateY(-50%); } } }
