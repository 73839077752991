@charset "utf-8";
.partner-blocks {
    .column {
        padding: 17px;
        .partner-block {
            background: #fff;
            border: 2px solid $black;
            box-shadow: 15px 15px 0 $black;
            width: calc(100% - 15px);
            height: calc(100% - 15px);
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            .image-container {
                border-bottom: 2px solid #232323;
                padding: 5px 15px;
                img {
                    display: block;
                    max-height: 108px;
                    margin: 0 auto;
                }
            }
            
            .text-container {
                padding: 10px 30px 15px 30px;
                display: flex;
                flex-direction: column;
                align-self: stretch;
                flex: 1;
                @media (min-width: $tablet) {
                    // min-height: 240px;
                }
                @media (min-width: $desktop) {
                    // min-height: 320px;
                }
                h5 {
                    margin-bottom: 0;
                }
                h3 {
                    margin-top: 0px;
                    margin-bottom: 10px;
                }
                p {
                    
                }
                a {
                    height: 31px;
                    margin-top: auto;
                    width: 140px
                }
            }
        }
        &:nth-child(1),
        &:nth-child(1n + 4) {
            .partner-block {
                border-color: $brand-green-light;
                box-shadow: 15px 15px 0px $brand-green-light;
                .image-container {
                    border-color: $brand-green-light;
                }
            }
        }
        &:nth-child(2),
        &:nth-child(2n + 4) {
            .partner-block {
                border-color: $brand-blue-light;
                box-shadow: 15px 15px 0px $brand-blue-light;
                .image-container {
                    border-color: $brand-blue-light;
                }
            }
        }
        &:nth-child(3),
        &:nth-child(3n + 4) {
            .partner-block {
                border-color: $brand-red-light;
                box-shadow: 15px 15px 0px $brand-red-light;
                .image-container {
                    border-color: $brand-red-light;
                }
            }
        }
        &:nth-child(4),
        &:nth-child(4n + 4) {
            .partner-block {
                border-color: $brand-yellow;
                box-shadow: 15px 15px 0px $brand-yellow;
                .image-container {
                    border-color: $brand-yellow;
                }
            }
        }
    }
}