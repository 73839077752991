@charset "utf-8";

.post {
  .cat-links,
  .tags-links,
  .comments-link {
    margin-right: 0.5em; }
  .archives & {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0; } } }
