.gform_wrapper {
	text-align: left;

	ul {
		margin-left: 0;

		li {
			list-style-type: none;
			margin: $gravity-margin 0 0;
		}
	}

	.gform_title,
	.gsection_title {
		font-size: $gravity-title-font-size;
	}

	.gform_fields {
		padding: 0;
	}

	.gfield {
		clear: both;
		input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
			padding: 8px 10px;
		}
		select {
			height: 40px;
			border-radius: 2px;
		}
	}

	.gfield_label {
		clear: both;
		display: inline-block;
		font-weight: 600;
		margin: $gravity-margin-small 0 0;

		@media( min-width: $gravity-small-screen ) {
			margin-top: $gravity-margin-medium;
		}
	}

	.gfield_description {
		font-size: $gravity-medium-font-size;
	}

	.gfield_required {
		color: $gravity-red;
		padding-left: 2px;
	}

	.field_description_above {

		.gfield_description {
			margin-bottom: $gravity-margin-small;

			@media( min-width: $gravity-small-screen ) {
				margin-bottom: $gravity-margin-medium;
			}
		}
	}

	.ginput_container {
		
		.small {
			width: 25%;
		}

		.medium {
			width: 50%;
		}

		.large {
			width: 100%;
		}
		
	}
	.top_label {
		.ginput_container {
			margin-top: 2px !important;
		}
	}

	.gform_footer {
		margin-top: $gravity-margin;
		input.button, 
		input[type=submit], 
		input.button, 
		input[type=submit] {
			padding-top: 0;
			padding-bottom: 0;
			background-color: #C51230;
    		border-color: transparent;
    		color: #fff;
			&:hover {
				background-color: #b9112d;
				border-color: transparent;
				color: #fff;
			}
		}
	}
}
