.gform_wrapper {

	.gform_drop_area {
		border: 2px dashed $gravity-medium-fog;
		color: $gravity-light-gray;
		margin-bottom: 10px;
		padding: 25px;
		text-align: center;
	}

}
