@charset "utf-8";

#header {
    padding: 5px 20px;
    border-bottom: 5px solid $brand-blue;
    @media (min-width: $tablet) {
        padding: 0px 30px;
    }
    .custom-logo-link {
        display: block;
        margin-top: 5px;
        margin-bottom: 5px;
        @media (min-width: $desktop) {
            margin-top: 20px;
            margin-bottom: 20px;
        }
        img {
            display: block;
            @media (max-width: $desktop) {
                max-height: 50px;
            }
        }
    }
}