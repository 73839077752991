.gform_wrapper {

	select {
		padding: 10px;
	}

	.ginput_container_multiselect {

		select {
			background: $gravity-white;
			padding: 0;

			option {
				padding: $gravity-form-input-padding / 2;
			}
		}
	}
}
