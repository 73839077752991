@charset "utf-8";

#site-navigation {
    .navbar-menu {

        a.navbar-item:focus,
        a.navbar-item:focus-within,
        a.navbar-item:hover,
        a.navbar-item.is-active,
        .navbar-link:focus,
        .navbar-link:focus-within,
        .navbar-link:hover,
        .navbar-link.is-active {
            background: transparent;
        }

        a {
            font-weight: 500;
            color: $brand-blue;
            text-decoration: none;
            &:hover {
                text-decoration: none;
            }
        }

        .navbar-item,
        .navbar-link {
            padding: 0.5rem 25px 0.5rem 10px;
        }
        .navbar-item > .navbar-link {
            padding-left: 0;
        }

        // .navbar-item.has-dropdown {
        //     padding: 0;
        // }

        .navbar-dropdown .navbar-item {
            padding: 0.5rem 25px;
            
        }
        ul > a.navbar-item {
            &:hover {
                &:before {
                    content: "";
                    height: 2px;
                    width: calc(100% - 33px);
                    position: absolute;
                    bottom: 38px;
                    background-color: $brand-blue;
                }
            }
        }
        .navbar-item {
            background: transparent;
            .navbar-link {
                &:not(.is-arrowless) {
                    padding-right: 45px;

                    &::after {
                        content: "\f0d7";
                        font-family: fontawesome, "Font Awesome 5 Free", "Font Awesome 5 Pro";
                        font-weight: 900;
                        font-size: 21px !important;
                        transform: rotate(-90deg);
                        transition: transform 0.3s ease;
                        width: auto;
                        height: auto;
                        border: none;
                        top: 42%;
                        right: 20px;
                        @media(max-width: $tablet) {
                            content: '';
                        }
                    }
                }
            }
            &:hover {
                & > .navbar-link {
                    text-decoration: none;
                    background: transparent;
                    &:before {
                        content: "";
                        height: 2px;
                        width: calc(100% - 20px);
                        position: absolute;
                        bottom: 30px;
                        background-color: $brand-blue;
                    }
                    &:not(.is-arrowless) {
                        &::after {
                            transform: rotateX(0deg);
                        }
                    }
                }
            }
        }
        
        .navbar-item > div:not(.navbar-dropdown) {
            a.navbar-item {
                &:hover {
                    &:before {
                        content: "";
                        height: 2px;
                        width: calc(100% - 33px);
                        position: absolute;
                        bottom: 38px;
                        background-color: $brand-blue;
                    }
                }
            }
        }
        
        @media screen and (min-width: $desktop) {
            .navbar-item.has-dropdown:focus .navbar-link, 
            .navbar-item.has-dropdown:hover .navbar-link, 
            .navbar-item.has-dropdown.is-active .navbar-link {
                background: transparent;
            }
            .navbar-dropdown {
                background-color: $brand-blue;
                color: $white;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                border-top: 2px solid $brand-blue;
                box-shadow: 0 8px 8px rgba(35, 35, 35, 0.1);
                a {
                    color: $white;

                    &:hover,
                    .is-active {
                        background-color: #0B265E;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 1023px ){
    .navbar-burger {
        color: $brand-blue;
        @include hamburger($navbar-height);
        margin-left: auto;
        width: 50px;
        padding: 0;
        &:before {
            content: "MENU";
            transition: opacity 0.2s ease;
            font-size: 10px;
            color: $brand-blue;
            font-weight: 700;
            text-align: center;
            position: absolute;
            top: 6px;
            left: 8px;
            font-family: Montserrat;
            letter-spacing: .25px;
        }
        span {
            background-color: $brand-blue;
            display: block;
            height: 3px;
            left: calc(50% - 25px);
            position: absolute;
            transform-origin: center;
            transition-duration: $speed;
            transition-property: background-color, opacity, transform;
            transition-timing-function: $easing;
            width: 50px;

            &:nth-child(1) {
                top: calc(50% - 9px);
            }

            &:nth-child(2) {
                top: calc(50% - 1px);
            }

            &:nth-child(3) {
                top: calc(50% + 7px);
            }
        }

        &:hover {
            background-color: rgba($black, 0.05);
        }

        // Modifers
        &.is-active {
            &:before {
                opacity: 0;
            }
            span {
                &:nth-child(1) {
                    transform: translateY(8px) rotate(45deg);
                }

                &:nth-child(2) {
                    opacity: 0;
                }

                &:nth-child(3) {
                    transform: translateY(-8px) rotate(-45deg);
                }
            }
        }
    }
}
@media(max-width: $tablet) {
    .navbar-dropdown {
        padding-top: 0;
        padding-bottom: 0;
    }
}
@media screen and (max-width: 1023px) {
    #site-navigation {
        .navbar-menu {
            background-color: $brand-blue;
            color: $white;
            box-shadow: 0 8px 16px rgba(35, 35, 35, 0.1);
            padding: 15px 0;
            width: calc(100% + 40px);
            top: 60px;
            left: -20px;
            ul {
                padding: 0px 20px;
            }
            a, a.navbar-item, a.navbar-link {
                color: #fff;
                &:hover,
                .is-active {
                    background-color: #0B265E !important;
                }
            } 
        }
    }
}

@media screen and (max-width: 1023px) {
    .parent-hide-mobile {
        display: none;
    }
    .child-show-mobile {
        padding-left: 0 !important;
    }
}