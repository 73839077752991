@charset "utf-8";

$padding: 40px;
$padding-mobile: 30px;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,700;1,500;1,700&display=swap');

@font-face {
    font-family: 'Alvar Essential';
    src: url('../fonts/alvaressential-medium-webfont.woff2') format('woff2'),
         url('fonts/alvaressential-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;

}

@font-face {
    font-family: 'Alvar Essential';
    src: url('fonts/alvaressential-mediumit-webfont.woff2') format('woff2'),
         url('../fonts/alvaressential-mediumit-webfont.woff') format('woff');
    font-weight: 500;
    font-style: italic;

}

@font-face {
    font-family: 'Alvar Essential';
    src: url('../fonts/alvaressential-semibold-webfont.woff2') format('woff2'),
         url('../fonts/alvaressential-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;

}

@font-face {
    font-family: 'Alvar Essential';
    src: url('../fonts/alvaressential-semiboldit-webfont.woff2') format('woff2'),
         url('../fonts/alvaressential-semiboldit-webfont.woff') format('woff');
    font-weight: 600;
    font-style: italic;

}
body {
    font-family: "Montserrat";
    
}

h1,
.h1 {
    font-family: "Alvar Essential";
    font-weight: 500;
    color: $brand-blue;
    font-size: 34px;
    line-height: 42px;
    @media(min-width: $tablet)  {
        font-size: 42px;
        line-height: 48px;
    }
    .content &,
    & a {
        font-family: "Alvar Essential";
        font-weight: 500;
        color: $brand-blue;
        font-size: 34px;
        line-height: 42px;
        @media(min-width: $tablet)  {
            font-size: 42px;
            line-height: 48px;
        } 
    }
        
}

h2,
.h2 {
    font-family: "Alvar Essential";
    font-weight: 500;
    color: $brand-blue;
    font-size: 28px;
    line-height: 36px;
    @media(min-width: $tablet)  {
        font-size: 36px;
        line-height: 42px;
    }
    .content &,
    & a {
        font-family: "Alvar Essential";
        font-weight: 500;
        color: $brand-blue;
        font-size: 28px;
        line-height: 36px;
        @media(min-width: $tablet)  {
            font-size: 36px;
            line-height: 42px;
        }
    }

    
}

h3,
.h3 {
    font-family: "Alvar Essential";
    font-weight: 500;
    color: $black;
    font-size: 24px;
    line-height: 28px;
    @media(min-width: $tablet)  {
        font-size: 28px;
        line-height: 36px;
    }
    .content &,
    & a {
        font-family: "Alvar Essential";
        font-weight: 500;
        color: $black;
        font-size: 24px;
        line-height: 28px;
        @media(min-width: $tablet)  {
            font-size: 28px;
            line-height: 36px;
        }
    }
    
}

h4,
.h4 {
    font-family: "Alvar Essential";
    font-weight: 600;
    color: $brand-blue;
    text-transform: uppercase;
    font-size: 21px;
    line-height: 24px;
    @media(min-width: $tablet)  {
        font-size: 24px;
        line-height: 20px;
    }
    .content &,
    & a {
        font-family: "Alvar Essential";
        font-weight: 600;
        color: $brand-blue;
        text-transform: uppercase;
        font-size: 21px;
        line-height: 24px;
        @media(min-width: $tablet)  {
            font-size: 24px;
            line-height: 20px;
        }  
    }
}

h5,
.h5 {
    font-family: "Alvar Essential";
    font-weight: 600;
    color: $brand-blue;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 18px;
    @media(min-width: $tablet)  {
        font-size: 16px;
        line-height: 18px;
    }
    .content &,
    & a {
        font-family: "Alvar Essential";
        font-weight: 600;
        color: $brand-blue;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 18px;
        @media(min-width: $tablet)  {
            font-size: 16px;
            line-height: 18px;
        }
    }
}

h6,
.h6 {
    @media(max-width: $tablet)  {
        
    }
    .content &,
    & a {
        font-family: "Alvar Essential";
        font-weight: 600;
        color: $brand-blue;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 18px;
        @media(min-width: $tablet)  {
            font-size: 16px;
            line-height: 18px;
        }
    }
}
p, a {
    font-family: "Montserrat";
    font-weight: 500;
    color: $black;
    font-size: 18px;
    line-height: 28px;
    @media(max-width: $tablet)  {
        font-size: 18px;
        line-height: 28px;
    }
}
p {
    margin-bottom: 12px;
}
a {
    color: $brand-blue;
    text-decoration: underline;
    &:hover {
        text-decoration: underline;
        color: $brand-blue-light;
    }
    &.arrow {
        font-family: "Montserrat";
        font-weight: 900;
        color: $brand-blue;
        text-decoration: none;
        &:hover {
            border-bottom: 2px solid $brand-blue-light;
            text-decoration: none;
            color: $brand-blue-light;
        }
        &:after {
            margin-left: 5px;
            font-size: 21px;
            line-height: 28px;
            font-family: fontawesome, "Font Awesome 5 Free", "Font Awesome 5 Pro";
            font-weight: 900;
            content: "\f0da";
            color: $brand-blue-light;
            bottom: -2px;
            position: relative;
            display: inline;
            white-space: nowrap;
        }
    }
}

.button,
a.button,
p a.button {
    font-family: "Alvar Essential";
    font-weight: 700;
    font-size: 18px; 
    text-transform: uppercase;
    background-color: transparent;
    color: $white;
    border-color: $white;
    text-decoration: none;
    &:not(.has-max){
        @media (max-width: 480px) {
            width: 100%;
        }
    }
    &:hover {
        text-decoration: none;
        color:$brand-blue;
        border-color: $white;
        background-color: $white;
        text-decoration: none;
    }
}


.container {
    max-width: 1090px;
}
.container-wide {
    max-width: 1315px;
}
  
section {
    padding: 40px 30px; 
    position: relative;
}
.section {
    padding: 0;
}
@media (min-width: $desktop) {
    .section {
        padding: 0;
    }
}
.order-1 {
    order: 1;
}
.order-2 {
    order: 2;
}
.order-3 {
    order: 3;
}
.order-4 {
    order: 4;
}



// Global Components
.intro {
    max-width: 867px;
    margin: 0 auto 40px;
}
.callout {
    padding: 20px;
    width: calc(100% - 15px);
    border-width: 2px;
    border-style: solid;
    background: $white;
    @media(max-width: $tablet) {
        margin-bottom: 40px;
    }
    @media (min-width: $tablet) {
        padding: 20px 30px 30px 30px;
        max-width: 430px;
        margin-bottom: 15px;
        .order-1 & {
            margin-right:auto;
            margin-left: 15px;
        }
        .order-2 & {
            margin-left:auto;
            margin-right: 15px;
        }
    }
}

.image-container {
    position: relative;
    overflow: visible;
        padding: 15px;

    img {
        z-index: 3;
        display: block;
        position: relative;
    }
    .left-corner,
    .right-corner {
        z-index: 0;
        width: 50%;
        height: 50%;
        position: absolute;
    }
    .left-corner {
        top: 0px;
        left: 0px;
        &.alt {
            top: auto;
            bottom: 0;
        }
    }
    .right-corner {
        bottom: 0px;
        right: 0px;
        &.alt {
            top: 0;
            bottom: auto;
        }
    }
}

.column {
    h2 {
        display: flex;
        align-items: center;
        .icon-container {
            width: 84px;
            display: block;
            margin-right: 20px;
            text-align: center;
            img {
                height: 72px;
                display: block;
                margin: auto;
            }
        }
    }
    ul:not(.slick-dots) {
        margin-top: 0;
        margin-left: 30px;
        li {
            list-style: none;
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 8px;
            position: relative;
            color: $black;
            @media(max-width: $tablet)  {
                font-size: 18px;
                line-height: 28px;
            }
            &:before {
                // content: "\f105";
                content: url('../images/bullet.svg');
                // font-family: "Font Awesome 5 Pro";
                font-weight: 900;
                position: absolute;
                left: -22px;
                top: 4px;
                // color: $brand-red;
    
            }
        }
    }
}

.arrow-down {
    width: 0; 
    height: 0; 
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid $brand-blue;
  }

  .generic-page  {
      .content {
          min-height: calc(100vh - 353px);
      }
  }