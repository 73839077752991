.tribe-common {
    li::before {
        display: none; 
    }
}

.event-date {
    text-transform: uppercase;
    text-align: left;
    color: $black !important;
    @media (min-width: $tablet) {
        font-size: 52px !important;
    }
}
.tribe-events-c-top-bar__datepicker {
    display: none;
}
.event-time {
    text-align: left;
    text-transform: uppercase;
    font-weight: 600;
    color: $black !important;
}
.tribe-common {
    .tribe-events-c-view-selector__list {
        display: none !important;
    }
    .button, a.button, p a.button {
        font-family: "Alvar Essential";
        font-weight: 700;
        font-size: 18px;
        text-transform: uppercase;
        color: white;
        border-color: white;
        border-width: 2px;
        cursor: pointer;
        justify-content: center;
        padding-bottom: 10px;
        padding-left: 38px;
        padding-right: 38px;
        padding-top: 10px;
        text-align: center;
        white-space: nowrap;
    }
}
.tribe-events-c-ical {
    display: none;
}
.tribe-events {
    .tribe-events-c-top-bar__nav-list-item {
        margin-top: 0;
    }
    .tribe-events-calendar-list__month-separator-text {
        text-transform: uppercase;
        font-weight: 600;
    }   
    .tribe-events-calendar-list__event-row {
        flex-direction: column;
        @media(min-width: $tablet) {
            flex-direction: row;
        }
        .tribe-events-calendar-list__event-wrapper {
            @media(max-width: $tablet) {
                width: 100%;
            }
        }
        .tribe-events-calendar-list__event-date-tag {
            width: 100%;
            @media(min-width: $tablet) {
                width: 24%;
            }
            @media(min-width: $desktop) {
                width: 22%;
            }
            .tribe-events-calendar-list__event-date-tag-datetime {
                .tribe-events-calendar-list__event-date-tag-daynum.h1.event-date {
                    @media (max-width: 480px) {
                        font-size: 21px;
                        text-transform: none;
                        line-height: 21px;
                        font-weight: 600;
                    }
                }
                .event-time {
                    @media (max-width: 480px) {
                        font-size: 21px;
                        font-weight: 600;
                    }
                }
            }
        }
        .tribe-events-calendar-list__event-details {
            width: 100%;
        }
    }

    .tribe-events-calendar-list__event-title {
        a {
            color: $brand-blue;
        }
    }
}
    
