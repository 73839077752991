@charset "utf-8";

html {
  background: none; }

html,
body,
.site {
  min-height: 100% !important; }

.site {
  display: flex;
  flex-direction: column;
  background: $white; }

.site-content {
  flex: 1; }

#header {
  flex-shrink: 0; }
