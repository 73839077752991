.gform_wrapper {

	.gfield_html,
	.gsection {
		// @include full();

		border-bottom: 1px dotted $gravity-light-gray;
		margin: 0 0 $gravity-margin-small;
		padding: 0 0 $gravity-margin-small;

		@media( min-width: $gravity-small-screen ) {
			margin-bottom: $gravity-margin-medium;
			padding-bottom: $gravity-margin-medium;
		}
	}

	.gfield_html {
		border-bottom: 0 none;
	}
}
