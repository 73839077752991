.content-news {
    background-color: $brand-tan;

    .news-list {

        .source,
        .source-date {
            font-size: 18px;
            color: #000;
        }

        a {
            text-decoration: underline;
        }

        > div:not(:last-of-type) {
            margin-bottom: 30px;
        }

        @media (min-width: 768px) {
            display: flex;
            justify-content: space-between;

            > div {
                margin-bottom: 0;
                width: calc(33.333333333% - 10px);
                max-width: 310px;
                
                &:not(:last-of-type) { margin-right: 15px; }
            }
        }
    }
}