.gform_wrapper {

	table {

		border: 0 none;
		margin: 0;

		td {
			border: 0 none;
			padding: 0;
		}
	}

	tbody {
		border: 0 none;
	}

	.gfield_list_icons {
		padding-left: 10px;
	}

}
